import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/docs-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Segments help define smaller subsets of your data collected with Firstparty. With Segments, you may analyze a
specific set of Events, count Sessions from a specific Source, or group Profiles with a known email address.`}</p>
    <p>{`Segments are limited to a single Item, and may be used later on to create Audiences from multiple Segment types.`}</p>
    <p>{`Each Segment applies only to Events, Sessions, or Profiles, and may be used to reduce the scope of a Report or increase
the number of Profiles added to an Audience.`}</p>
    <h2 {...{
      "id": "defining-segments",
      "style": {
        "position": "relative"
      }
    }}>{`Defining Segments`}<a parentName="h2" {...{
        "href": "#defining-segments",
        "aria-label": "defining segments permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Segments are defined by the combination of an Item type (Events, Sessions, or Profiles) and a set of filters that
limit the returned Items which meet the criteria you've defined in each filter.`}</p>
    <h3 {...{
      "id": "segment-filters",
      "style": {
        "position": "relative"
      }
    }}>{`Segment Filters`}<a parentName="h3" {...{
        "href": "#segment-filters",
        "aria-label": "segment filters permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`Filters let you select how a specific Item Property must exactly match, include, not match, or not include a specific
value. You may add an unlimited number of Filters to a Segment definition, and update them at any time.`}</p>
    <h3 {...{
      "id": "how-filters-are-evaluated",
      "style": {
        "position": "relative"
      }
    }}>{`How Filters are Evaluated`}<a parentName="h3" {...{
        "href": "#how-filters-are-evaluated",
        "aria-label": "how filters are evaluated permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <p>{`You may choose to require `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`any`}</code>{` or `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`all`}</code>{` included Filters to be required when evaluating a Segment. When selecting `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`any`}</code>{`
as a choice for Filter inclusion, Items will be included with the Segment if any of the defined Filters evaluate true.
If you select `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`all`}</code>{` as a choice for Filter inclusion, Itmes will only be included in the Segment if every defined Filter
evaluates at true.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      